import React from "react";
import { Div, Icon, Text, Anchor } from "atomize";
import promoLogo from "../assets/recraft.jpg";

const HeroCard = () => {
  return (
    <Div
      m={{
        x: { xs: ".25rem", sm: "10rem", xl: "20rem" },
      }}
      p={{ b: "1rem" }}
    >
      <Anchor href= "https://go.recraft.ai/araltasher_ig" target="_blank">
        <Div
          d="flex"
          align="center"
          justify="space-between"
          bg={"#16161A"}
          shadow="4"
          rounded="xl"
          m={{ b: "1rem" }}
        >
          <Div
            bgImg={promoLogo}
            bgSize="cover"
            bgPos="center"
            h="5rem"
            w="5rem"
            rounded="lg"
          />
          <Div d="flex" flexDir="column">
            <Text
              textSize={{ xs: "subheading",  xl: "heading" }}
              textWeight="500"
              textColor="gray100"
            >
              Try Recraft AI
            </Text>
            <Text textSize={{ xs: "caption", xl: "caption" }} textColor="light">
            Use ARAL12 to get 1 month FREE
            </Text>
          </Div>

          <Div
            p={{
              r: "1rem",
            }}
            m={{ b: "1rem", t: "20px" }}
          >
            <Icon name="LongRight" size="30px" color="gray100" />
          </Div>
        </Div>
      </Anchor>
    </Div>
  );
};

export default HeroCard;
