import React, { useEffect, useState } from 'react';
import { Container } from 'atomize';
import youtube from './utils/fetchYT';
import YouTube from 'react-youtube';

import HeroCard from './components/HeroCard';
import Lightroom from './components/Lightroom';
import PromoCard from './components/PromoCard';
import ImageCard from './components/ImageCard';
import SmallCards from './components/SmallCards';
import SmallCardsImage from './components/SmallCardsImage';
import Footer from './components/Footer';
import Loader from './components/Loader';

import SetupImg from './assets/setup-large.jpg';

function App() {
  const [ytVideo, setYTVideo] = useState(0);

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      rel: 0,
      listType: 'user',
    },
  };

  const fetchData = async () => {
    const req = await youtube.get('/playlistItems', {
      params: {
        playlistId: 'UU5WfyxrsmEA5pKi7AjMzf8g',
      },
    });
    setYTVideo(req);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const _onReady = (e) => {
    // access to player in all event handlers via event.target
    e.target.pauseVideo();
  };

  if (ytVideo.data) {
    const longForm = ytVideo.data.items.filter(
      (video) => video.snippet.description
    );
    // console.log(longForm);


    const { resourceId } = longForm[0].snippet;
    return (
      <>
        <Container justify="center">
          <HeroCard />
          <Lightroom />
          {/* <PromoCard /> */}
          <YouTube
          style={{overflow:"hidden"}}
            videoId={resourceId.videoId}
            opts={opts}
            onReady={(e) => _onReady(e)}
          />
          <PromoCard />
          <ImageCard
            url="https://amazon.com/shop/araltasher/list/3JS73IA82UOX4"
            cover={SetupImg}
            title="Developer EDC"
            subtitle="Checkout my Amazon Storefront"
            transformCoordinate={{
              xs: 'translateY(-90px)',
              sm: 'translateY(-100px)',
            }}
          />
          <SmallCardsImage />
          <YouTube
          style={{overflow:"hidden"}}
          videoId={"knUVvhYcO7U"}
          opts={opts}
          onReady={_onReady}
          />
          <SmallCards />
          {/* <SmallCards /> */}
        </Container>
        <Footer />
      </>
    );
  }

  return <Loader />;
}

export default App;
